import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Snackbar,
  Typography,
  TextField,
  Modal,
  Paper,
} from '@mui/material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import CodeCard from '../CodeCard';
import {
  useCreateOrUpdateCodedChartMutation,
  useFetchChartDetailsQuery,
  useFetchSlowChartDataQuery,
} from '../../../apis/chart';

const SecondPassFinalizedCodes = ({
  completedCodes,
  handleRemoveFromCompleted,
  aiIdentifiedConditions,
  userSelectedCodes,
  onDragEnd,
  codeReviewStatus,
  humanCodedChart,
}) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [modalOpen, setModalOpen] = useState(false);
  const [rationaleData, setRationaleData] = useState([]);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('success');
  const [createOrUpdateCodedChart, { isLoading: isLoadingCodedChartMutation }] =
    useCreateOrUpdateCodedChartMutation();
  const {
    data: chart,
    isFetching: isFetchingChart,
    refetch: refetchDetail,
  } = useFetchChartDetailsQuery(id);
  const { data: slowData } = useFetchSlowChartDataQuery(id);

  const handleFinalizeClick = () => {
    // Initialize rationale data when opening the modal
    setRationaleData(
      completedCodes.map((code) => ({
        code: code.code,
        description: code.description || '',
        rationale: code?.full_ref.second_pass_rationale || '',
      })),
    );
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleRationaleChange = (index, value) => {
    setRationaleData((prev) => {
      const updated = [...prev];
      updated[index].rationale = value;
      return updated;
    });
  };

  const handleModalSubmit = async () => {
    try {
      await createOrUpdateCodedChart({
        id: humanCodedChart?.uuid,
        chart: chart.uuid,
        source: 'Human',
        confirmed_codes: rationaleData.map(({ code, rationale }) => ({
          code,
          rationale,
        })),
        processing_time: 0,
        is_processed: true,
      }).unwrap();
      refetchDetail();
      setModalOpen(false);
      setAlertMessage('Successfully finalized codes');
      setAlertSeverity('success');
      if (slowData?.next_chart) {
        navigate(`/chart/${slowData?.next_chart}`);
      }
    } catch (error) {
      console.error('Failed to finalize codes:', error);
      setAlertMessage(
        'Failed to finalize codes. Please refresh the page and try again.',
      );
      setAlertSeverity('error');
    }
  };

  const handleUnlockCodes = async () => {
    try {
      await createOrUpdateCodedChart({
        id: humanCodedChart?.uuid,
        chart: chart.uuid,
        is_processed: false,
      }).unwrap();
      refetchDetail();
      setAlertMessage('Successfully unlocked codes!');
      setAlertSeverity('success');
    } catch (error) {
      console.error('Failed to unlock codes:', error);
      setAlertMessage(
        'Failed to unlock codes. Please refresh the page and try again.',
      );
      setAlertSeverity('error');
    }
  };

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginLeft: 2,
          marginBottom: 1,
        }}
      >
        <Typography variant="h6">Finalized Codes</Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={
            humanCodedChart?.is_processed
              ? handleUnlockCodes
              : handleFinalizeClick
          }
          disabled={isLoadingCodedChartMutation || isFetchingChart}
          startIcon={
            isLoadingCodedChartMutation || isFetchingChart ? (
              <CircularProgress size={14} />
            ) : null
          }
        >
          {humanCodedChart?.is_processed ? 'Unlock' : 'Finalize'}
        </Button>
        <Snackbar
          open={!!alertMessage}
          autoHideDuration={3000}
          onClose={() => setAlertMessage('')}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert
            onClose={() => setAlertMessage('')}
            severity={alertSeverity}
            sx={{ width: '100%' }}
          >
            {alertMessage}
          </Alert>
        </Snackbar>
      </Box>
      <Box
        sx={{
          padding: 2,
          border: '1px solid #ccc',
          backgroundColor: '#f9f9f9',
          height: '100%',
        }}
      >
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="completedCodes">
            {(provided) => (
              <Box
                ref={provided.innerRef}
                {...provided.droppableProps}
                sx={{ minHeight: '80%', overflowY: 'auto' }}
              >
                {completedCodes.map((code, index) => (
                  <Draggable
                    key={code.code}
                    draggableId={code.code}
                    index={index}
                  >
                    {(provided) => (
                      <Box
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        sx={{ position: 'relative', paddingBottom: '1rem' }}
                      >
                        <CodeCard
                          code={code}
                          action={handleRemoveFromCompleted}
                          chevronLeft
                          aiIdentifiedConditions={aiIdentifiedConditions}
                          userSelectedCodes={userSelectedCodes}
                          reviewResult={
                            codeReviewStatus?.[chart.uuid]?.[code.code]
                              ?.reviewResult
                          }
                          chart={chart}
                        />
                      </Box>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Box>
            )}
          </Droppable>
        </DragDropContext>
      </Box>

      {/* Modal */}
      <Modal open={modalOpen} onClose={handleModalClose}>
        <Paper
          sx={{
            width: '80%',
            margin: 'auto',
            marginTop: '5%',
            padding: 4,
          }}
        >
          <Typography variant="h6" gutterBottom>
            Finalize Codes
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            {rationaleData.length > 0 ? (
              rationaleData.map((item, index) => (
                <Box
                  key={item.code}
                  sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    justifyContent: 'space-between',
                    gap: 2,
                  }}
                >
                  <Box sx={{ flex: 1 }}>
                    <Typography variant="body1">{item.code}</Typography>
                    <Typography variant="body2" color="textSecondary">
                      {item.description}
                    </Typography>
                  </Box>
                  <Box sx={{ flex: 2 }}>
                    <TextField
                      label="Rationale"
                      value={item?.rationale}
                      onChange={(e) =>
                        handleRationaleChange(index, e.target.value)
                      }
                      fullWidth
                    />
                  </Box>
                </Box>
              ))
            ) : (
              <Typography variant="body2">No codes to finalize.</Typography>
            )}
          </Box>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleModalSubmit}
            sx={{ marginTop: 3 }}
          >
            Submit
          </Button>
        </Paper>
      </Modal>
    </Box>
  );
};

export default SecondPassFinalizedCodes;
