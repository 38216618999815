import React, { useState, useEffect, useRef } from 'react';
import { Alert, Box, Typography, Grid, Snackbar } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import theme from '../../../styles/theme';
import ConditionsList from '../ConditionsList';
import ICDCodeSearch from '../ICDCodeSearch';
import { useFetchChartDetailsQuery } from '../../../apis/chart';
import { useParams } from 'react-router-dom';
import SecondPassFinalizedCodes from './SecondPassFinalizedCodes';

const SecondPassCompletedCodesBuilder = ({
  highlightedText,
  handleCodeClick,
  humanCodedChart,
}) => {
  const { id } = useParams();
  const { data: chart } = useFetchChartDetailsQuery(id);

  // Extract coded charts from your main chart data
  const codedCharts = chart?.coded_charts || [];
  const aiCodedChart =
    codedCharts.find((cc) => cc.source === 'AI') || {};
  const humanCodedCharts = codedCharts.filter((cc) => cc.source === 'Human');

  // This map will store the "master" data for each code (including second pass stuff)
  // so you never lose data when removing/re-adding.
  const [aggregatorMap, setAggregatorMap] = useState({});
  // completedCodes: which codes are currently "finalized" in the right column
  const [completedCodes, setCompletedCodes] = useState([]);
  // userSelectedCodes: any custom codes you add from ICDCodeSearch
  const [userSelectedCodes, setUserSelectedCodes] = useState([]);
  // alert when chart is locked
  const [alertOpen, setAlertOpen] = useState(false);

  // We'll track if we've run the aggregator so we don't overwrite local changes repeatedly
  const aggregatorRanRef = useRef(false);

  /**
   * 1) Build aggregatorMap from all processed human-coded charts (multi-coder).
   *    Then we also set up the initial completedCodes based on that aggregator map.
   *    Only run once (or when data truly changes).
   */
  useEffect(() => {
    if (aggregatorRanRef.current) return; // skip if we've already aggregated
    if (!humanCodedCharts.length) return;

    const codeMap = {};

    humanCodedCharts.forEach((codedChart) => {
      // For second pass, you said you may have multiple coders
      // We'll combine codes from any that is "processed"
      if (codedChart.is_processed) {
        const confirmedCodes = codedChart.coded_chart_code_references || [];
        const coderUsername = codedChart.coder_details?.username || 'Unknown';

        confirmedCodes.forEach((codeRef) => {
          // e.g. 'I10'
          const codeVal = codeRef.icd_code?.code;
          if (!codeVal) return; // skip if empty

          // If aggregator doesn't have this code yet, add it
          if (!codeMap[codeVal]) {
            codeMap[codeVal] = {
              ...codeRef.icd_code, // => { code: 'I10', description: 'Hypertension', etc. }
              condition: codeRef.condition?.name || 'Unknown',
              rationale: codeRef.rationale || '',
              coders: [coderUsername],
              full_ref: codeRef,
            };
          } else {
            // If code is already in aggregator, just add coder if missing
            if (!codeMap[codeVal].coders.includes(coderUsername)) {
              codeMap[codeVal].coders.push(coderUsername);
            }
          }
        });
      }
    });

    // Convert codeMap to local aggregatorMap
    setAggregatorMap(codeMap);

    // Also set completedCodes to all aggregator codes if you want them "ready" by default
    // Alternatively, only do this if you want to pre-populate the right column with aggregated codes
    const aggregatedList = Object.values(codeMap).map((entry) => ({
      ...entry,
      coders: entry.coders.join(', '), // store as string if needed
    }));

    setCompletedCodes(aggregatedList);

    aggregatorRanRef.current = true;
  }, [humanCodedCharts]);

  /**
   * 2) Group AI conditions for the left column
   */
  const groupAICodesByCondition = (codedChart) => {
    if (!codedChart.coded_chart_code_references) return [];
    return codedChart.coded_chart_code_references.reduce((acc, ref) => {
      const conditionName = ref.condition?.name || 'Unknown';
      const existing = acc.find((g) => g.condition === conditionName);

      if (existing) {
        existing.codes.push(ref.icd_code);
        existing.full_ref.push(ref);
      } else {
        acc.push({
          condition: conditionName,
          codes: [ref.icd_code],
          full_ref: [ref],
          highlight_text: ref.highlight_text || '',
          rationale: ref.rationale || '',
        });
      }
      return acc;
    }, []);
  };
  const aiIdentifiedConditions = groupAICodesByCondition(aiCodedChart);

  /**
   * 3) Handle user search -> user adds code
   */
  const handleAddCode = (code) => {
    setUserSelectedCodes((prev) => [...prev, { ...code, user_added: true }]);
  };

  /**
   * 4) Move code from left to right if unlocked
   *    We pull the "full" aggregator data from aggregatorMap if available so we don't lose second pass details.
   */
  const handleMoveToCompleted = (code) => {
    if (humanCodedChart?.is_processed) {
      setAlertOpen(true);
      return;
    }
    const key = code.code || code.icd_code; // some codes from AI might have .icd_code
    if (!key) {
      // fallback
      code.code = 'UNKNOWN_CODE';
    }

    // If aggregatorMap has enriched data for that code, let's use that
    const aggregatorObj = aggregatorMap[key];
    if (aggregatorObj) {
      // aggregatorObj might have second pass rationale, coders, etc.
      // We'll keep any local overrides from 'code', but aggregator data overrides are prioritized
      // so that we definitely keep second pass details
      const mergedObj = {
        ...code,
        ...aggregatorObj,
        code: key,
        coders: aggregatorObj.coders?.join?.(', ') || aggregatorObj.coders || '',
      };
      setCompletedCodes((prev) => [...prev, mergedObj]);
    } else {
      // aggregator doesn't have it => we just add the code as is
      setCompletedCodes((prev) => [...prev, { ...code, code: key }]);
    }
  };

  /**
   * 5) Remove code from completed codes if unlocked
   *    We do NOT remove it from aggregatorMap => aggregator data stays in memory for re-adding
   */
  const handleRemoveFromCompleted = (code) => {
    if (humanCodedChart?.is_processed) {
      setAlertOpen(true);
      return;
    }
    setCompletedCodes((prev) => prev.filter((item) => item.code !== code.code));
  };

  /**
   * 6) Reorder codes if unlocked
   */
  const onDragEnd = (result) => {
    if (!result.destination || humanCodedChart?.is_processed) return;
    const reorder = Array.from(completedCodes);
    const [removed] = reorder.splice(result.source.index, 1);
    reorder.splice(result.destination.index, 0, removed);
    setCompletedCodes(reorder);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box
        sx={{
          padding: 2,
          border: '1px solid #ccc',
          borderRadius: 2,
          height: '100%',
          overflow: 'hidden',
        }}
      >
        <ICDCodeSearch
          onAddCode={handleAddCode}
          aiIdentifiedCodes={
            aiCodedChart.coded_chart_code_references?.map((ref) => ref.icd_code) || []
          }
        />

        <Grid container spacing={2} sx={{ height: 'calc(100% - 80px)' }}>
          {/* Left column: AI + user codes */}
          <Grid item xs={12} md={6} sx={{ height: '100%', overflowY: 'auto' }}>
            <Typography variant="h6" sx={{ marginLeft: 2, marginBottom: 1 }}>
              AI Suggested Codes
            </Typography>
            <Box
              sx={{
                padding: 2,
                border: '1px solid #ccc',
                backgroundColor: '#f9f9f9',
              }}
            >
              <ConditionsList
                aiIdentifiedConditions={aiIdentifiedConditions}
                completedCodes={completedCodes}
                userSelectedCodes={userSelectedCodes}
                handleMoveToCompleted={handleMoveToCompleted}
                handleCodeClick={handleCodeClick}
                highlightedText={highlightedText}
                chart={chart}
              />
            </Box>
          </Grid>

          {/* Right column: Completed/finalized codes */}
          <Grid item xs={12} md={6} sx={{ height: '100%', overflowY: 'auto' }}>
            <SecondPassFinalizedCodes
              completedCodes={completedCodes}
              handleRemoveFromCompleted={handleRemoveFromCompleted}
              aiIdentifiedConditions={aiIdentifiedConditions || []}
              userSelectedCodes={userSelectedCodes}
              onDragEnd={onDragEnd}
              humanCodedChart={humanCodedChart}
            />
          </Grid>
        </Grid>

        <Snackbar
          open={alertOpen}
          autoHideDuration={6000}
          onClose={() => setAlertOpen(false)}
        >
          <Alert
            onClose={() => setAlertOpen(false)}
            severity="warning"
            sx={{ width: '100%' }}
          >
            The chart is locked. No changes can be made.
          </Alert>
        </Snackbar>
      </Box>
    </ThemeProvider>
  );
};

export default SecondPassCompletedCodesBuilder;
