import React from 'react';
import { Box } from '@mui/material';
import WorkflowTable from './WorkflowSteps/WorkflowTable';

const ChartTable = ({ chartSource }) => {
  return (
    <Box sx={{ padding: '10px' }}>
      <Box sx={{ padding: '20px' }}>
        <WorkflowTable chartSource={chartSource} />
      </Box>
    </Box>
  );
};

export default ChartTable;
