import React, { useState, useEffect } from 'react';
import {
  Box,
  TextField,
  Typography,
  Switch,
  Button,
  Alert,
  IconButton,
  Chip,
  FormControl,
  Select,
  MenuItem,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useUpdateChartMutation } from '../../apis/chart';
import { useUpdatePatientMutation } from '../../apis/patients';
import { TRIAGE } from '../../utils';

const ChartDetailsEditable = ({ chart, onSave }) => {
  const [editedChart, setEditedChart] = useState({ ...chart });
  const [editedPatient, setEditedPatient] = useState({ ...chart.patient });
  const [encounters, setEncounters] = useState([...chart.encounters]);
  const [newEncounter, setNewEncounter] = useState({
    date_of_service_start: '',
    date_of_service_end: '',
  });
  const [showEncounterForm, setShowEncounterForm] = useState(false);

  const [qualifiesFaceToFace, setQualifiesFaceToFace] = useState(false);
  const [isSigned, setIsSigned] = useState(false);
  const [qualifiedVirtual, setQualifiedVirtual] = useState(false);
  const [outOfScope, setOutOfScope] = useState(false);
  const [outOfScopeReason, setOutOfScopeReason] = useState('');
  const [showError, setShowError] = useState(false);

  const [updateChart, { isLoading: saving, error: saveError }] =
    useUpdateChartMutation();
  const [updatePatient] = useUpdatePatientMutation();

  useEffect(() => {
    setEditedChart({
      ...chart,
      out_of_scope_reason: chart?.out_of_scope_reason || '',
      qualifies_face_to_face: chart?.qualifies_face_to_face || false,
      is_signed: chart?.is_signed || false,
      qualified_virtual: chart?.qualified_virtual || false,
    });
    setEditedPatient({
      ...chart.patient,
    });
    setEncounters([...chart.encounters]);
    setQualifiesFaceToFace(chart?.qualifies_face_to_face || false);
    setIsSigned(chart?.is_signed || false);
    setQualifiedVirtual(chart?.qualified_virtual || false);
    setOutOfScope(!!chart?.out_of_scope_reason);
    setOutOfScopeReason(chart?.out_of_scope_reason || '');
  }, [chart]);

  const handleOutOfScopeReasonChange = (e) => {
    const value = e.target.value;
    setOutOfScopeReason(value);
    if (value !== 'Not codable - other') {
      setEditedChart((prev) => ({ ...prev, out_of_scope_reason: value }));
    }
  };

  const handleOutOfScopeOtherChange = (e) => {
    const value = e.target.value;
    setEditedChart((prev) => ({ ...prev, out_of_scope_reason: value }));
  };

  const handleDeleteEncounter = (index) => {
    const updatedEncounters = [...encounters];
    updatedEncounters.splice(index, 1);
    setEncounters(updatedEncounters);
  };

  const handleAddEncounter = () => {
    if (!newEncounter.date_of_service_start) {
      return;
    }
    if (!newEncounter.date_of_service_end) {
      newEncounter.date_of_service_end = newEncounter.date_of_service_start;
    }
    setEncounters([...encounters, newEncounter]);
    setNewEncounter({ date_of_service_start: '', date_of_service_end: '' });
    setShowEncounterForm(false);
  };

  const handleSubmit = async () => {
    if (outOfScope && !editedChart.out_of_scope_reason) {
      setShowError(true);
      return;
    }
    setShowError(false);

    try {
      // Update patient
      await updatePatient(editedPatient);

      // Update chart and encounters
      await updateChart({
        id: chart.uuid,
        qualifies_face_to_face: qualifiesFaceToFace,
        is_signed: isSigned,
        qualified_virtual: qualifiedVirtual,
        out_of_scope_reason: outOfScope ? editedChart.out_of_scope_reason : '',
        encounters,
        current_wf_step: TRIAGE,
      });

      onSave();
    } catch (err) {
      console.error('Error saving data:', err);
    }
  };

  const outOfScopeOptions = [
    'Not codable - no clinical info',
    'Not codable - not a visit',
    'Not codable - not signed',
    'Not codable - incomplete chart/failed split',
    'Not codable - other',
    'Codable - Not a 2023 DOS',
    'Codable - Not a F2F Encounter',
  ];

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 4,
        backgroundColor: '#f9f9f9',
        padding: 3,
        borderRadius: 2,
        border: '1px solid #ddd',
      }}
    >
      {/* Demographics */}
      <Box>
        <Typography variant="h6" gutterBottom>
          Demographics
        </Typography>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <TextField
            name="name"
            label="Name"
            value={editedPatient.name}
            onChange={(e) =>
              setEditedPatient({ ...editedPatient, name: e.target.value })
            }
            size="small"
          />
          <TextField
            name="dob"
            label="DOB"
            value={editedPatient.dob}
            onChange={(e) =>
              setEditedPatient({ ...editedPatient, dob: e.target.value })
            }
            size="small"
          />
        </Box>
      </Box>

      {/* Encounters */}
      <Box>
        <Typography variant="h6" gutterBottom>
          Encounters
        </Typography>
        {encounters.map((encounter, index) => (
          <Box
            key={index}
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
              marginBottom: 2,
            }}
          >
            <Chip
              label={`${encounter.date_of_service_start} - ${encounter.date_of_service_end}`}
              color="primary"
              variant="outlined"
              sx={{ padding: '4px 8px', fontSize: '0.875rem' }}
            />
            <IconButton
              color="error"
              onClick={() => handleDeleteEncounter(index)}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        ))}
        {showEncounterForm ? (
          <Box sx={{ display: 'flex', gap: 2, marginTop: 2 }}>
            <TextField
              label="Start Date"
              type="date"
              InputLabelProps={{ shrink: true }}
              value={newEncounter.date_of_service_start}
              onChange={(e) =>
                setNewEncounter((prev) => ({
                  ...prev,
                  date_of_service_start: e.target.value,
                }))
              }
              size="small"
            />
            <TextField
              label="End Date"
              type="date"
              InputLabelProps={{ shrink: true }}
              value={newEncounter.date_of_service_end}
              onChange={(e) =>
                setNewEncounter((prev) => ({
                  ...prev,
                  date_of_service_end: e.target.value,
                }))
              }
              size="small"
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddEncounter}
            >
              Add
            </Button>
          </Box>
        ) : (
          <Button
            variant="contained"
            color="primary"
            onClick={() => setShowEncounterForm(true)}
            sx={{ marginTop: 2 }}
          >
            Add Encounter
          </Button>
        )}
      </Box>

      {/* Chart Status */}
      <Box>
        <Typography variant="h6" gutterBottom>
          Chart Status
        </Typography>
        <Box sx={{ display: 'flex', gap: 4 }}>
          {/* Left Column */}
          <Box sx={{ flex: 1 }}>
            <Box>
              <Typography variant="body1">Qualifies as Face to Face</Typography>
              <Switch
                checked={qualifiesFaceToFace}
                onChange={(e) => setQualifiesFaceToFace(e.target.checked)}
              />
            </Box>
            <Box>
              <Typography variant="body1">Is Signed</Typography>
              <Switch
                checked={isSigned}
                onChange={(e) => setIsSigned(e.target.checked)}
              />
            </Box>
            <Box>
              <Typography variant="body1">Qualified Virtual Visit</Typography>
              <Switch
                checked={qualifiedVirtual}
                onChange={(e) => setQualifiedVirtual(e.target.checked)}
              />
            </Box>
          </Box>

          {/* Right Column */}
          <Box sx={{ flex: 1 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <Typography variant="body1">Out of Scope</Typography>
              <Switch
                checked={outOfScope}
                onChange={(e) => setOutOfScope(e.target.checked)}
              />
            </Box>
            {outOfScope && (
              <Box>
                <FormControl fullWidth>
                  {/*<InputLabel id="out-of-scope-label">*/}
                  {/*  Out of Scope Reason*/}
                  {/*</InputLabel>*/}
                  <Select
                    labelId="out-of-scope-label"
                    value={outOfScopeReason}
                    onChange={handleOutOfScopeReasonChange}
                  >
                    {outOfScopeOptions.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {outOfScopeReason === 'Not codable - other' && (
                  <TextField
                    label="Specify Reason"
                    value={editedChart.out_of_scope_reason}
                    onChange={handleOutOfScopeOtherChange}
                    fullWidth
                    sx={{ marginTop: 2 }}
                  />
                )}
                {showError && (
                  <Alert severity="error" sx={{ marginTop: 2 }}>
                    Please select an Out of Scope reason before submitting.
                  </Alert>
                )}
              </Box>
            )}
          </Box>
        </Box>
      </Box>

      {/* Confirm Button */}
      <Button
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        disabled={saving}
      >
        {saving ? 'Saving...' : 'Confirm'}
      </Button>

      {/* Error Display */}
      {saveError && (
        <Typography color="error" sx={{ marginTop: 2 }}>
          Failed to save changes: {saveError.message}
        </Typography>
      )}
    </Box>
  );
};

export default ChartDetailsEditable;
