import React, { useState, useEffect, useCallback } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import {
    useFetchChartsQuery,
    useDeleteChartMutation,
} from '../../apis/chart';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Tooltip,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import LaunchIcon from '@mui/icons-material/Launch';
import {filenameComparator, OUT_OF_SCOPE} from "../../utils";

const OutOfScopeCharts = ({ chartSource }) => {
    const navigate = useNavigate();

    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 50,
    });

    const {
        data,
        error,
        isLoading,
        isFetching,
        refetch,
    } = useFetchChartsQuery({
        page: paginationModel.page + 1,
        pageSize: paginationModel.pageSize,
        wf_step: OUT_OF_SCOPE,
        chartSource,
    });

    const [deleteChart] = useDeleteChartMutation();
    const [open, setOpen] = useState(false);
    const [selectedId, setSelectedId] = useState(null);

    useEffect(() => {
        refetch();
    }, [paginationModel, refetch]);

    const handlePaginationModelChange = useCallback(
        (newModel) => {
            if (newModel.pageSize !== paginationModel.pageSize) {
                setPaginationModel({ ...newModel, page: 0 });
            } else {
                setPaginationModel(newModel);
            }
        },
        [paginationModel.pageSize]
    );

    const handleDelete = async () => {
        await deleteChart(selectedId);
        handleClose();
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedId(null);
    };

    const handleOpenDetails = (id) => {
        navigate(`/chart/${id}`);
    };
    const handleOpenDetailsNewTab = (id) => {
        window.open(`/chart/${id}`, '_blank');
    };

    // We assume "second pass" charts are already verified, so submittable/blocked doc deficiency
    // aren't relevant. But let's still define columns for a consistent look.
    const columns = [
        {
            field: 'date_of_service',
            headerName: 'DOS',
            flex: 1,
            renderCell: (params) => dayjs(params.row.date_of_service).format('MM-DD-YYYY'),
        },
        {
            field: 'patient',
            headerName: 'Patient',
            flex: 1,
        },
        {
            field: 'file_gcs_location',
            headerName: 'Filename',
            flex: 2,
            valueGetter: (value, row) => row.file_gcs_location,
            sortComparator: filenameComparator,
        },
                {
            field: 'out_of_scope_reason',
            headerName: 'Reason',
            flex: 2,
            valueGetter: (value, row) => row.out_of_scope_reason,
            sortComparator: filenameComparator,
        },
        {
            field: 'open',
            headerName: 'Open',
            sortable: false,
            width: 80,
            renderCell: (params) => (
                <IconButton
                    onClick={() => handleOpenDetails(params.id)}
                    data-testid={`chart-detail-button-${params.id}`}
                >
                    <OpenInNewIcon />
                </IconButton>
            ),
        },
        {
            field: 'openNewTab',
            headerName: 'New Tab',
            sortable: false,
            width: 80,
            renderCell: (params) => (
                <Tooltip title="New Tab">
                    <IconButton
                        onClick={() => handleOpenDetailsNewTab(params.id)}
                        aria-label="Open in New Tab"
                    >
                        <LaunchIcon />
                    </IconButton>
                </Tooltip>
            ),
        },
    ];

    // Build the rows from the API data
    const rows = data?.data?.map((chart) => {
        // We'll gather the Human coder usernames
        const humanCodedCharts = chart.coded_charts.filter((cc) => cc.source === 'Human');
        // Extract coder usernames
        const coderUsernames = humanCodedCharts.map((cc) =>
            cc.coder_details?.username || `Coder#${cc.coder_details?.id}`
        );

        return {
            id: chart.uuid,
            date_of_service: chart.date_of_service,
            file_gcs_location: chart.file_gcs_location,
            patient: chart.patient
                ? `${chart.patient.name} (${chart.patient.gender})`
                : 'Unknown',
            out_of_scope_reason: chart?.out_of_scope_reason || '',
            coderUsernames,
        };
    }) || [];

    if (isLoading || isFetching) return <div>Loading out of scope charts...</div>;
    if (error) return <div>Error: {error.message}</div>;

    return (
        <>
            <DataGrid
                pagination
                rows={rows}
                columns={columns}
                rowsPerPageOptions={[25, 50, 100]}
                paginationMode="server"
                rowCount={data?.total || 0}
                paginationModel={paginationModel}
                onPaginationModelChange={handlePaginationModelChange}
                pageSizeOptions={[25, 50, 100]}
                initialState={{
                    sorting: {
                        sortModel: [
                            { field: 'file_gcs_location', sort: 'asc' },
                            { field: 'patient', sort: 'asc' },
                        ],
                    },
                }}
            />

            {/* Confirm Deletion Dialog */}
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Confirm Deletion</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this chart? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDelete} color="primary" autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default OutOfScopeCharts;
