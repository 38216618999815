import React from 'react';
import { Box, Card, CardContent, Typography } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import CodeCard from './CodeCard';
import theme from '../../styles/theme';
import ConditionHeader from './ConditionHeader';

const ConditionsList = ({
  aiIdentifiedConditions,
  completedCodes,
  userSelectedCodes,
  handleMoveToCompleted,
  handleCodeClick,
  highlightedText,
  chart,
}) => {
  // Annotate user added codes
  const userAvailableCodes = userSelectedCodes.map((code) => ({
    ...code,
    condition: 'User Added',
    highlight_text: '',
    rationale: '',
  }));

  // Combine AI and User added codes
  const combinedAvailableCodes = aiIdentifiedConditions
    .flatMap((group) =>
      group.codes.map((code) => ({
        ...code,
        condition: group.condition,
        highlight_text: group.highlight_text,
        rationale: group.rationale,
        full_ref: group.full_ref[0], // TODO - this is weird, we should clean up this section
      })),
    )
    .concat(userAvailableCodes);

  const filteredAvailableCodes = combinedAvailableCodes.filter(
    (code) =>
      !completedCodes.some((completedCode) => completedCode.code === code.code),
  );

  // Group codes by condition
  const groupedCodes = filteredAvailableCodes.reduce((acc, code) => {
    const condition = code.condition;
    const existingGroup = acc.find((group) => group.condition === condition);
    if (existingGroup) {
      existingGroup.codes.push(code);
    } else {
      acc.push({
        condition: condition,
        codes: [code],
        highlight_text: code.highlight_text,
        rationale: code.rationale,
      });
    }
    return acc;
  }, []);

  // Separate User Added codes
  const userAddedGroup = groupedCodes.find(
    (group) => group.condition === 'User Added',
  );
  const aiGroupedGroups = groupedCodes.filter(
    (group) => group.condition !== 'User Added',
  );

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ height: '100%', overflowY: 'auto' }}>
        {/* User Added Codes */}
        {userAddedGroup && userAddedGroup.codes.length > 0 && (
          <Card key="User Added">
            <CardContent>
              <Typography variant="h6" sx={{ marginBottom: 1 }}>
                User Added
              </Typography>
              {userAddedGroup.codes
                .sort((a, b) => a.code.localeCompare(b.code))
                .map((codeObj) => {
                  const codeKey = codeObj.code;

                  return (
                    <Box sx={{ position: 'relative' }}>
                      <CodeCard
                        key={codeKey}
                        code={codeObj}
                        action={handleMoveToCompleted}
                        chevronRight
                        aiIdentifiedConditions={aiIdentifiedConditions}
                        userSelectedCodes={userSelectedCodes}
                        handleCodeClick={handleCodeClick}
                      />
                    </Box>
                  );
                })}
            </CardContent>
          </Card>
        )}

        {/* AI Identified Conditions */}
        {aiGroupedGroups.map((group) => (
          <Card key={group.condition}>
            <CardContent>
              <ConditionHeader
                group={group}
                handleCodeClick={handleCodeClick}
                highlightedText={highlightedText}
                isExpanded={true}
              />
              {group.codes
                .sort((a, b) => a.code.localeCompare(b.code))
                .map((codeObj) => {
                  const codeKey = codeObj.code;

                  return (
                    <Box sx={{ position: 'relative' }}>
                      <CodeCard
                        key={codeKey}
                        code={codeObj}
                        chart={chart}
                        action={handleMoveToCompleted}
                        aiIdentifiedConditions={aiIdentifiedConditions}
                        userSelectedCodes={userSelectedCodes}
                        handleCodeClick={handleCodeClick}
                      />
                    </Box>
                  );
                })}
            </CardContent>
          </Card>
        ))}
      </Box>
    </ThemeProvider>
  );
};

export default ConditionsList;
