import React, { useState } from "react";
import {Box, Tabs, Tab} from "@mui/material";
import {useFetchChartCountsByStateQuery} from "../../apis/chart";
import {useGetUserQuery} from "../../apis/users";
import FirstPassCharts from "./FirstPassCharts";
import SecondPassCharts from "./SecondPassCharts";
import TriageCharts from "./TriageCharts";
import {COMPLETED, FIRST_PASS, OUT_OF_SCOPE, SECOND_PASS, TRIAGE} from "../../utils";
import OutOfScopeCharts from "./OutOfScopeCharts";
import CompletedCharts from "./CompletedCharts";
import theme from "../../styles/theme";

const WorkflowTable = ({ chartSource }) => {
    const [tabValue, setTabValue] = useState(0);

    const {
        data: chartCounts,
        isLoading,
        error,
    } = useFetchChartCountsByStateQuery(chartSource);

    const {
        data: currentUser,
        isLoading: userIsLoading,
        error: userError,
    } = useGetUserQuery();

    if (isLoading || userIsLoading) {
        return <div>Loading…</div>;
    }
    if (error) {
        return <div>Error loading chartCounts: {error.message}</div>;
    }
    if (userError) {
        return <div>Error loading user: {userError.message}</div>;
    }


    const isCoderAdmin = (currentUser?.groups ?? []).includes("CoderAdmin");

    const triageCount = chartCounts?.[TRIAGE] ?? 0;
    const firstPassCount = chartCounts?.[FIRST_PASS] ?? 0;
    const secondPassCount = chartCounts?.[SECOND_PASS] ?? 0;
    const completedCount = chartCounts?.[COMPLETED] ?? 0;
    const outOfScopeCount = chartCounts?.[OUT_OF_SCOPE] ?? 0;

    const adminTabs = [
        {
            label: `Triage (${triageCount})`,
            content: (
                <Box>
                    <TriageCharts chartSource={chartSource}/>
                </Box>
            ),
        },
        {
            label: `1st pass (${firstPassCount})`,
            content: (
                <Box>
                    <FirstPassCharts chartSource={chartSource}/>
                </Box>
            ),
        },
        {
            label: `2nd pass (${secondPassCount})`,
            content: (
                <Box>
                    <SecondPassCharts chartSource={chartSource}/>
                </Box>
            ),
        },
        {
            label: `Completed (${completedCount})`,
            content: (
                <Box>
                    <CompletedCharts chartSource={chartSource}/>
                </Box>
            ),
        },
        {
            label: `Out of Scope (${outOfScopeCount})`,
            content: (
                <Box>
                    <OutOfScopeCharts chartSource={chartSource}/>
                </Box>
            ),
        },
    ];

    const coderTabs = [
        {
            label: `Triage (${triageCount})`,
            content: (
                <Box>
                    <TriageCharts chartSource={chartSource}/>
                </Box>
            ),
        },
        {
            label: `1st pass (${firstPassCount})`,
            content: (
                <Box>
                    <FirstPassCharts chartSource={chartSource}/>
                </Box>
            ),
        },
    ];

    const tabsToShow = isCoderAdmin ? adminTabs : coderTabs;

    if (tabValue >= tabsToShow.length) {
        setTabValue(0);
    }

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <Box>
            <Tabs value={tabValue} onChange={handleTabChange}
                  sx={{
                      '& .MuiTabs-indicator': {
                          display: 'none',
                      },
                      '& .MuiButtonBase-root.MuiTab-root': {
                          color: theme.palette.primary.main,
                          backgroundColor: 'transparent',
                          borderRadius: '16px',
                          transition: 'all 0.2s ease-in-out',
                          padding: '6px 16px',
                          '&:hover': {
                              color: theme.palette.secondary.main,
                              backgroundColor: '#f0f0f0',
                          },
                          '&.Mui-selected': {
                              color: 'white',
                              backgroundColor: theme.palette.primary.main
                          },
                      },
                  }}
            >
                {tabsToShow.map((tab, index) => (
                    <Tab key={index} label={tab.label} sx={{fontSize: '18px'}}/>
                ))}
            </Tabs>
            <Box sx={{marginTop: 2}}>{tabsToShow[tabValue].content}</Box>
        </Box>
    );
};

export default WorkflowTable;
