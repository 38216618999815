// /* CSS HEX */
/* CSS HEX */
// --cool-gray: #8b94a3ff;
// --aquamarine: #8cfbdeff;
// --spring-green: #0fff95ff;
// --emerald: #06ba63ff;
// --pakistan-green: #103900ff;
//

// $cool-gray: #F6F6F6;
import { createTheme } from '@mui/material/styles';

// Define the custom theme
const theme = createTheme({
    palette: {
        primary: {
            main: '#41658aff', // Lapis Lazuli
        },
        secondary: {
            main: '#70a37fff', // Cambridge Blue
        },
        error: {
            main: '#d32f2f', // Red (default error color)
        },
        background: {
            default: '#F6F6F6', // Mantis
            paper: '#ffffff', // White background for paper components
        },
        text: {
            primary: '#414073ff', // Delft Blue
            secondary: '#4c3957ff', // English Violet
        },
        warning: {
            main: '#ffbf46ff', // Xanthous for warning
        },
        success: {
            main: '#70a37fff', // Cambridge Blue for success
        },
        highlight: {
            main: '#FFFF00' // for the source highlighters
        },
        workflows: {
            green: '#66bb6a',
            yellow: '#ffd54f'
        },
        codes: {
            listed: {
                background: '#70a37fff',
                color: '#ffffff',
            },
            ai: {
                background: '#ffbf46ff',
                color: '#414073ff',
            },
            user: {
                background: '#ff7043',
                color: '#ffffff',
            },
        },
    },
    typography: {
        fontFamily: 'Roboto, Arial, sans-serif', // Set the global font family
    },
    components: {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    background: 'linear-gradient(90deg, hsl(210, 36%, 40%) 0%, hsl(210, 36%, 50%) 100%)',
                }
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundColor: '#ffffff', // White background for paper components
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 8,
                    textTransform: 'none',
                },
            },
        },
    },
});

export default theme;