import React, { useState } from 'react';
import { Box, Card, Typography, Button } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { CheckCircle, Warning, ExpandMore, ExpandLess } from '@mui/icons-material';
import { green, amber } from '@mui/material/colors';
import theme from '../../styles/theme';
import { SECOND_PASS } from '../../utils';

const getCodeCardStyle = (code, aiIdentifiedConditions = [], userSelectedCodes = []) => {
    const isAIIdentified = aiIdentifiedConditions?.some(aiCondition =>
        aiCondition.codes && aiCondition.codes.some(aiCode => aiCode?.code === code?.code)
    );

    // If not found in AI-identified conditions, consider it user-selected
    const isUserSelected = !isAIIdentified || userSelectedCodes.some(userCode => userCode.code === code.code);

    if (isUserSelected) {
        return {
            backgroundColor: theme.palette.codes.user.background,
            color: theme.palette.codes.user.color,
        };
    } else if (isAIIdentified) {
        return {
            backgroundColor: theme.palette.codes.ai.background,
            color: theme.palette.codes.ai.color,
        };
    } else {
        return {
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.text.primary,
        };
    }
};

const CodeCard = ({
    code,
    chart,
    action,
    chevronLeft = false,
    aiIdentifiedConditions = [],
    userSelectedCodes = [],
}) => {
        const fullRef = code.full_ref;

    // Determine if the code is appropriate
    const isAppropriate = fullRef
        ? ['Definitely', 'Probably'].includes(fullRef.appropriate)
        : null;
    const workflowStepParam = chart?.workflow_state;

    // State to control visibility of the rationale
    const [showRationale, setShowRationale] = useState(false);

    // Function to toggle rationale visibility
    const toggleRationale = (e) => {
        e.stopPropagation(); // Prevent triggering other onClick events
        setShowRationale((prev) => !prev);
    };
    return (
        <Card key={code.code} sx={{ marginTop: 0, marginBottom: 0, padding: 0, display: 'flex' }}>
            <Box sx={{ width: 8, ...getCodeCardStyle(code, aiIdentifiedConditions, userSelectedCodes) }} />
            <Box
                onClick={() => action(code)}
                sx={{
                    flex: 1,
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    padding: 1,
                }}
            >
                {chevronLeft && <ChevronLeftIcon />}
                <Box sx={{ flex: 1, marginLeft: chevronLeft ? 2 : 0 }}>
                    <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                        {code.code}
                    </Typography>
                    <Typography variant="body2">{code.description}</Typography>

                    {code.coders && code.coders.length > 0 && (
                        <Typography variant="body2" sx={{ marginTop: 1, fontStyle: 'italic' }}>
                            <strong>Coded By:</strong> {code?.coders}
                        </Typography>
                    )}

                    {workflowStepParam && workflowStepParam === SECOND_PASS && (
                        <>
                            {fullRef && (
                                <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 1 }}>
                                    {isAppropriate ? (
                                        <CheckCircle sx={{ color: green[500], marginRight: 1 }} />
                                    ) : (
                                        <Warning sx={{ color: amber[700], marginRight: 1 }} />
                                    )}
                                    <Typography variant="subtitle2">{fullRef.appropriate}</Typography>

                                    <Button
                                        onClick={toggleRationale}
                                        size="small"
                                        sx={{ marginLeft: 'auto', textTransform: 'none' }}
                                        aria-label={showRationale ? 'Hide rationale' : 'Show rationale'}
                                        endIcon={showRationale ? <ExpandLess /> : <ExpandMore />}
                                    />
                                </Box>
                            )}

                            {/* Conditionally render the rationale */}
                            {fullRef && showRationale && (
                                <Typography variant="body2" sx={{ marginTop: 1 }}>
                                    <strong>AI Review Rationale:</strong> {fullRef.second_pass_rationale}
                                </Typography>
                            )}
                        </>
                    )}
                </Box>
                {!chevronLeft && <ChevronRightIcon />}
            </Box>
        </Card>
    );
};

export default CodeCard;
