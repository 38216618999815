import React, { useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { Edit } from '@mui/icons-material';
import {
  useFetchChartSourcesQuery,
  useModifyCustomerUsersMutation,
} from '../../apis/chart';
import { useFetchUsersQuery } from '../../apis/users';

const AdminSources = () => {
  const {
    data: sources = [],
    isLoading,
    error,
    refetch,
  } = useFetchChartSourcesQuery();
  const { data: usersData = { results: [] }, isLoading: usersLoading } =
    useFetchUsersQuery();
  const [modifyCustomerUsers] = useModifyCustomerUsersMutation();

  const [open, setOpen] = useState(false);
  const [editingSource, setEditingSource] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const handleOpenDialog = (source) => {
    setEditingSource(source);
    setSelectedUsers(source.users); // Directly set the user objects
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
    setEditingSource(null);
    setSelectedUsers([]);
  };

  const handleUserAction = async (sourceId, userIds, redundancyPercentage) => {
    try {
      await modifyCustomerUsers({
        customerName: sourceId,
        userIds,
        redundancyPercentage,
      });
      refetch();
    } catch (error) {
      console.error('Error modifying user association:', error);
    }
  };

  const handleSubmit = async () => {
    const userIds = selectedUsers.map((user) => user.id);
    try {
      await handleUserAction(
        editingSource.source,
        userIds,
        editingSource.redundancy_percentage,
      );
      handleCloseDialog();
    } catch (error) {
      console.error('Error modifying user association:', error);
    }
  };

  if (isLoading || usersLoading) {
    return <Typography>Loading...</Typography>;
  }

  if (error) {
    return <Typography>Error loading sources or users.</Typography>;
  }

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h4" gutterBottom>
        Chart Sources
      </Typography>
      <Box sx={{ marginTop: 3 }}>
        {sources.map((source) => (
          <Box
            key={source.id}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: 2,
              borderBottom: '1px solid #ccc',
            }}
          >
            <Box
              sx={{ display: 'flex', alignItems: 'center', gap: 2, flex: 1 }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  flex: 1,
                }}
              >
                <Typography variant="body1">
                  <strong>Source:</strong> {source.source}
                </Typography>
                <Typography variant="body2">
                  <strong>Redundancy Percent:</strong>{' '}
                  {source.redundancy_percentage || 0}%
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', flex: 1 }}>
                <Typography variant="body2">
                  <strong>Users:</strong>{' '}
                  {source.users.length > 0
                    ? source.users.map((user) => user.username).join(', ')
                    : 'None'}
                </Typography>
              </Box>
            </Box>
            <Box>
              <IconButton
                onClick={() => handleOpenDialog(source)}
                color="primary"
              >
                <Edit />
              </IconButton>
            </Box>
          </Box>
        ))}
      </Box>

      <Dialog open={open} onClose={handleCloseDialog}>
        <DialogTitle>
          {editingSource
            ? `Edit Users for ${editingSource.source}`
            : 'Edit Users'}
        </DialogTitle>
        <DialogContent>
          <FormControl fullWidth margin="dense">
            <Autocomplete
              multiple
              options={usersData.results}
              getOptionLabel={(option) => option.username}
              value={selectedUsers}
              onChange={(event, newValue) => setSelectedUsers(newValue)}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Users"
                  placeholder="Select users"
                />
              )}
            />
          </FormControl>
          <TextField
            fullWidth
            margin="dense"
            label="Redundancy Percentage"
            type="number"
            value={editingSource?.redundancy_percentage || 0}
            onChange={(e) =>
              setEditingSource({
                ...editingSource,
                redundancy_percentage: e.target.value,
              })
            }
            inputProps={{ min: 0, max: 100 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AdminSources;
