import React, { useEffect, useRef } from 'react';
import Highlighter from 'react-highlight-words'; // highlight library
import { Box, Typography } from '@mui/material';

/**
 * Expects a JSON string like:
 * '[{"snippet":"Hypotension - possibly secondary to medication adjustments."},{"snippet":"..."}]'
 *
 * Returns an array of snippet strings, e.g.:
 * ["Hypotension - possibly secondary to medication adjustments.", "..."]
 */
function parseSnippetArray(snippetStr) {
  if (!snippetStr) return [];
  try {
    const arr = JSON.parse(snippetStr); // parse the JSON
    if (!Array.isArray(arr)) {
      return [];
    }
    // Extract the `snippet` field from each object
    return arr
      .map((item) => item.snippet)
      .filter((s) => typeof s === 'string' && s.length > 0);
  } catch (err) {
    console.error('Invalid JSON for snippetStr:', snippetStr, err);
    return [];
  }
}

const HighlightedContent = ({ highlightedText, chart }) => {
  const containerRef = useRef(null);

  // 1) Parse the JSON snippet array
  //    Example input: '[{"snippet": "Hypotension..."}, {"snippet": "another snippet"}]'
  const searchSnippets = parseSnippetArray(highlightedText);

  // 2) Scroll to first highlight if available
  useEffect(() => {
    if (containerRef.current && searchSnippets.length > 0) {
      // Wait a tick for the DOM to render
      setTimeout(() => {
        // Use the highlight class that react-highlight-words applies by default
        const highlightEls =
          containerRef.current.querySelectorAll('.highlight');
        if (highlightEls.length > 0) {
          highlightEls[0].scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }, 0);
    }
  }, [searchSnippets, chart]);

  // 3) If there is no content, render nothing
  if (!chart?.content) {
    return null;
  }

  return (
    <Box
      ref={containerRef}
      sx={{ padding: 2, height: '100%', overflowY: 'auto' }}
    >
      <Typography
        variant="body1"
        component="div"
        sx={{ whiteSpace: 'pre-wrap' }}
      >
        {/* 4) Use react-highlight-words to highlight all snippet strings. */}
        <Highlighter
          highlightClassName="highlight" // default highlight class
          searchWords={searchSnippets}
          autoEscape={true}
          textToHighlight={chart.content}
        />
      </Typography>
    </Box>
  );
};

export default HighlightedContent;
