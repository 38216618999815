import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {AppBar, Toolbar, Button, Box, IconButton, Menu, MenuItem, Avatar} from '@mui/material';
import {Person} from '@mui/icons-material';
import {selectToken} from "../Auth/selectors";
import {logout} from '../apis/auth';
import {ThemeProvider} from '@mui/material/styles';
import theme from '../styles/theme';
import {chartApiSlice} from '../apis/chart';
import ChartSourceSelector from "./ChartSourceSelector";
import {useGetUserQuery} from "../apis/users";

function Header({setChartSource}) {
    const token = useSelector(selectToken);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = useState(null);
    const [adminMenuAnchor, setAdminMenuAnchor] = useState(null);

    // Fetch current user info
    const {data: currentUser} = useGetUserQuery(undefined, {
        skip: !token,
    });
    const isCoderAdmin = (currentUser?.groups ?? []).includes('CoderAdmin') || currentUser?.is_superuser;

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleAdminMenuOpen = (event) => {
        setAdminMenuAnchor(event.currentTarget);
    };

    const handleAdminMenuClose = () => {
        setAdminMenuAnchor(null);
    };

    const handleLogout = () => {
        // Dispatch logout so Redux state is cleared
        dispatch(logout());

        // Close the menu
        handleMenuClose();

        // Force full redirect to home page - triggers a hard reload of the app
        window.location.href = "/";
    };

    const handleLogin = () => {
        navigate('/login');
    };

    const handleHome = () => {
        navigate('/');
        window.location.href = "/";
        // Invalidate "SlowData" tags when returning to the chart table
        dispatch(chartApiSlice.util.invalidateTags(["SlowData"]));
    };

    const handleNavigate = (path) => {
        navigate(path);
        handleAdminMenuClose();
    };

    // Determine username display logic
    // let usernameDisplay = "Loading...";
    // if (!isLoading) {
    //     if (error) {
    //         usernameDisplay = "Error";
    //     } else {
    //         usernameDisplay = currentUser?.username || "Unknown";
    //     }
    // }

    return (
        <ThemeProvider theme={theme}>
            <AppBar position="static">
                <Toolbar>
                    <Box
                        component="img"
                        src="/healthmc_logo.png"
                        alt="HealthMC Logo"
                        sx={{
                            height: 40,
                            cursor: 'pointer',
                            borderRadius: '8px',
                        }}
                        onClick={handleHome}
                    />


                    <Box sx={{flexGrow: 1}}/>
                    {token ? (

                        <>
                            <Box sx={{display: 'flex', alignItems: 'center', padding: '10px'}}>
                                <ChartSourceSelector
                                    onChartSourceChange={setChartSource}
                                />
                            </Box>


                            <Button color="inherit" onClick={handleHome} sx={{marginLeft: 3}}>
                                Charts
                            </Button>
                            {isCoderAdmin &&
                                <>
                                    <Button
                                        color="inherit"
                                        onClick={handleAdminMenuOpen}
                                        sx={{marginLeft: 3}}
                                    >
                                        Admin
                                    </Button>


                                    <Menu
                                        anchorEl={adminMenuAnchor}
                                        open={Boolean(adminMenuAnchor)}
                                        onClose={handleAdminMenuClose}
                                    >
                                        <MenuItem onClick={() => handleNavigate('/users')}>Users</MenuItem>
                                        <MenuItem onClick={() => handleNavigate('/sources')}>Sources</MenuItem>
                                    </Menu>
                                </>
                            }
                            <IconButton
                                edge="end"
                                color="inherit"
                                onClick={handleMenuOpen}
                                sx={{marginLeft: 3}}
                            >
                                <Avatar>
                                    <Person/>
                                </Avatar>
                            </IconButton>
                            <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleMenuClose}
                            >
                                <MenuItem onClick={handleLogout}>Logout</MenuItem>
                            </Menu>
                        </>
                    ) : (
                        <>
                            <Button color="inherit" onClick={handleLogin}>
                                Login
                            </Button>
                        </>
                    )}
                </Toolbar>
            </AppBar>
        </ThemeProvider>
    );
}

export default Header;
