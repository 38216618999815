import React from 'react';
import ChartTable from './ChartTable';

const LandingPage = ({ chartSource }) => {
  return (
    <div className="home-container">
      <ChartTable chartSource={chartSource} />
    </div>
  );
};

export default LandingPage;
